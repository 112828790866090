import nearText from '../../images/near-logo-text.svg';
import nextIcon from '../../images/near-logo-icon.svg';
import { event } from '../../../config/event';
import styles from './Logo.module.css';
import cn from 'classnames';
import brandLogo from '../../images/brand/brand.svg';
import consensusLogo from '../../images/consensus/consensus-logo.png';
import auroraLogo from '../../images/aurora/aurora-logo.png';
import octopusLogo from '../../images/octopus/octopus-logo.png';
import braveLogo from '../../images/brave/brave-logo.png';
import armoredLogo from '../../images/armored-kingdom/armored-kingdom-logo.png';
import sweatLogo from '../../images/sweat/sweat-logo.png';
import unchainLogo from '../../images/unchain/unchain-logo.png';
import mintbaseLogo from '../../images/mintbase/mintbase-logo.png';
import sailgpLogo from '../../images/bermuda/bermuda.png';

const showLogo = ['sf', 'miami'];
const showNearText = ['sf', 'miami', 'hongbao'];
const showLogoText = ['sf', 'miami', 'hongbao', 'brand', 'bermuda'];
const showConsensusLogo = ['consensus', 'aurora', 'octopus', 'brave', 'armored', 'sweat', 'unchain', 'mintbase', 'standards'];

const BrandIcon = () => {
  return <img class={styles.brandLogo} src={brandLogo} alt="brand logo" />;
};

const BermudaIcon = () => {
  return (
    <>
      <img className={cn(styles.nearText, styles[event.name])} src={sailgpLogo} alt="SailGP" />
      <p class={styles.bermudaText}>Jump in, create a wallet, and start your voyage.</p>
    </>
  );
}

const DropIcon = () => {
  let dropEvent = '';
  switch (event.name) {
    case 'consensus':
      dropEvent = consensusLogo;
      break;
    case 'standards':
      dropEvent = consensusLogo;
      break;
    case 'aurora':
      dropEvent = auroraLogo;
      break;
    case 'octopus':
      dropEvent = octopusLogo;
      break;
    case 'brave':
      dropEvent = braveLogo;
      break;
    case 'armored':
      dropEvent = armoredLogo;
      break;
    case 'sweat':
      dropEvent = sweatLogo;
      break;
    case 'unchain':
      dropEvent = unchainLogo;
      break;
    case 'mintbase':
      dropEvent = mintbaseLogo;
      break;
    default:
      dropEvent = consensusLogo;
      break;
  }

  return <img src={dropEvent} alt="NEAR" className={cn(styles.nearText, styles[event.name])} />;
}

export const Logo = () => (
  <div className={cn(styles.container, styles[event.name])}>
    {showNearText.includes(event.name) && (
      <img src={nearText} alt="NEAR" className={cn(styles.nearText, styles[event.name])} />
    )}
    {showLogo.includes(event.name) && (
      <img src={nextIcon} alt="NEAR logo" className={styles.nearIcon} />
    )}
    {showConsensusLogo.includes(event.name) && (
      <DropIcon />
    )}
    {event.name === 'brand' && <BrandIcon />}
    {['bermuda', 'sailgp', 'notart'].includes(event.name) && <BermudaIcon />}
    {showLogoText.includes(event.name) && (
      <img
        src={event.logoText}
        alt="Event city"
        className={cn(styles.eventName, styles[event.name])}
      />
    )}
  </div>
);
