import miami from '../ui/images/miami/miami.svg';
import nyc from '../ui/images/newYork/nyc.svg';
import sanFrancisco from '../ui/images/sf/san-francisco.svg';
import hongbao from '../ui/images/hongbao/hongbao.svg';
import brand from "../ui/images/brand/brand-copy.svg";
import bermuda from "../ui/images/bermuda/bermuda.png";
import consensus from "../ui/images/consensus/consensus-logo.png";

const events = {
  miami: {
    name: 'miami',
    logoText: miami,
    mainUrl: 'miami.near.org',
  },
  newYork: {
    name: 'newYork',
    logoText: nyc,
    mainUrl: 'nyc.near.org',
  },
  sf: {
    name: 'sf',
    logoText: sanFrancisco,
    mainUrl: 'sf.near.org',
  },
  hongbao: {
    name: 'hongbao',
    logoText: hongbao,
    mainUrl: 'hongbao.near.org',
  },
  ethdenver: {
    name: 'ethdenver',
    logoText: '',
    mainUrl: 'ethdenver.near.org',
  },
  brand: {
    name: 'brand',
    logoText: brand,
    mainUrl: 'brand.near.org',
  },
  bermuda: {
    name: 'bermuda',
    logoText: bermuda,
    mainUrl: 'bermuda.near.org',
  },
  consensus: {
    name: 'consensus',
    logoText: consensus,
    mainUrl: 'consensus.near.org',
  },
  aurora: {
    name: 'aurora',
    logoText: '',
    mainUrl: 'aurora.near.org',
  },
  octopus: {
    name: 'octopus',
    logoText: '',
    mainUrl: 'octopusnetwork.near.org',
  },
  brave: {
    name: 'brave',
    logoText: '',
    mainUrl: 'bravewallet.near.org',
  },
  armored: {
    name: 'armored',
    logoText: '',
    mainUrl: 'armoredkingdom.near.org',
  },
  sweat: {
    name: 'sweat',
    logoText: '',
    mainUrl: 'sweateconomy.near.org',
  },
  unchain: {
    name: 'unchain',
    logoText: '',
    mainUrl: 'unchainfund.near.org',
  },
  mintbase: {
    name: 'mintbase',
    logoText: '',
    mainUrl: 'mintbase.near.org',
  },
  sailgp: {
    name: 'sailgp',
    logoText: '',
    mainUrl: 'sailgp.near.org',
  },
  standards: {
    name: 'standards',
    logoText: '',
    mainUrl: 'standards.near.org',
  },
  notart: {
    name: 'notart',
    logoText: '',
    mainUrl: 'iamnotartxsgp.near.org',
  },
};

export const event = events[process.env.REACT_APP_EVENT];
